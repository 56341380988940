import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Query, QueryParam } from 'common/api/Query'
import { Notification } from 'modules/notifications/models/Notification'
import { TransportType } from 'common/enums/TransportType'
import { getNotificationContainer } from 'container/notification-module'
import { NotificationService } from 'modules/notifications/services/NotificationService'
import { MESSAGES_SERVICE_KEY, NOTIFICATION_SERVICE_KEY } from 'modules/notifications'
import { Message } from 'modules/notifications/models/Message'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { getUserCircleContainer } from 'container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from 'modules/user-circle'
import { forkJoin, Observable } from 'rxjs'
import { MessageService } from 'modules/notifications/services/MessageService'
import { SourceType } from 'modules/notifications/enums/SourceType'
import trainingIcon from 'assets/dashboard/trainingIcon.svg'
import formIcon from 'assets/dashboard/formIcon.svg'
import resourcesIcon from 'assets/dashboard/resourceIcon.svg'
import calendarIcon from 'assets/dashboard/calendarIcon.svg'
import chatIcon from 'assets/dashboard/chatIcon.svg'
import libraryIcon from 'assets/dashboard/libraryIcon.svg'
import symptomIcon from 'assets/dashboard/symptomIcon.svg'
import treatmentIcon from 'assets/dashboard/treatmentIcon.svg'
import style from './NotificationCard.module.scss'
import { listItemTextStyle } from './NotificationsCardStyleMui'
import { useNavigate } from 'react-router-dom'
import {
  ROUTE_CALENDAR,
  ROUTE_MESSENGER,
  ROUTE_PATIENT_FORMS,
  ROUTE_PATIENT_SYMPTOMS,
  ROUTE_QUERY,
  ROUTE_RESOURCES,
  ROUTE_TREATMENTS,
  ROUTE_USERS,
} from 'routes/routes-constants'
import { DeliveryStatus } from 'modules/notifications/enums/DeliveryStatus'
import { UserCircleWithRelatersAndCircle } from 'modules/user-circle/models/UserCircleWithRelatersAndCircle'

type NotificationsCardProps = {
  ProfessionalNotifications: boolean
  SetNumberNewResources: (number: number) => void
  selectedUserCircle: UserCircleWithRelatersAndCircle | undefined
}
const notificationsPerPage = 7
const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
const messageService = notificationContainer.get<MessageService>(MESSAGES_SERVICE_KEY)

export const NotificationsCardPatient: React.FC<NotificationsCardProps> = (props): JSX.Element => {
  const userCircle = userCircleActiveService.getActiveFullUserCircle()
  const loggedUser = loggedUserService.get()
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [filteredNotifications, setFilteredNotifications] = useState<Notification[]>([])
  const [notificationMessages, setNotificationMessages] = useState<Map<string, Message>>(new Map())
  const navigate = useNavigate()

  const [page] = useState<number>(1)

  const getMessages = (ids: string[]): Observable<Message[]> =>
    forkJoin(ids.map((id) => messageService.getByID(id))) as unknown as Observable<Message[]>

  useEffect(() => {
    if (!userCircle || !loggedUser) return
    notificationService
      .getFilteredList(
        new Query({
          pager: { offset: (page - 1) * notificationsPerPage, limit: notificationsPerPage },
          query: [
            new QueryParam<Notification>(
              'recipientID',
              props.ProfessionalNotifications ? loggedUser?.id : userCircle.user.id
            ),
            new QueryParam<Notification>('transportType', TransportType.App),
          ],
          sort: [{ field: 'sendAt', desc: true }],
        })
      )
      .subscribe((res) => {
        // seteo de numero de notificaciones de nuevos recursos para el numeric stats card de abajo del dashboard
        const newFiles = res.items.filter((n) => n.sourceType === SourceType.FilesNewCreated)
        props.SetNumberNewResources(newFiles.length)
        setNotifications(res.items.sort((a, b) => b.sendAt.getTime() - a.sendAt.getTime()))

        console.log(res)
        const notificationMessagesTmp = new Map()

        getMessages(res.items.map((n) => n.message)).subscribe((ml) => {
          ml.forEach((m, i) => {
            notificationMessagesTmp.set(res.items[i].id, m)
          })
          setNotificationMessages(new Map(notificationMessagesTmp))
        })
      })
  }, [])

  useEffect(() => {
    // filtro las notificaciones por el circulo seleccionado en el dashboard
    if (!props.selectedUserCircle) {
      setFilteredNotifications(notifications)
      return
    }
    const filtered = notifications.filter((n) => n.userCircleID === props.selectedUserCircle?.id)
    setFilteredNotifications(filtered)
  }, [props.selectedUserCircle, notifications])

  const handleOnClick = (notification: Notification) => {
    if (notification.deliveryStatus !== DeliveryStatus.Viewed) {
      // Actualiza el estado de entrega a "Visto"
      notification.deliveryStatus = DeliveryStatus.Viewed
      // Llama al servicio de notificaciones para actualizar el estado
      notificationService.update(notification).subscribe()
    }
    switch (notification.sourceType) {
      case SourceType.CalendarEventCreated:
      case SourceType.CalendarEventUpdated:
        navigate(ROUTE_CALENDAR)
        break
      case SourceType.FilesNewCreated:
        navigate(ROUTE_RESOURCES)
        break
      case SourceType.FormsNewUserForm:
        navigate(ROUTE_PATIENT_FORMS)
        break
      case SourceType.FormsUserFormFilled:
        navigate(ROUTE_PATIENT_FORMS)
        break
      case SourceType.PatientSymptomCreated:
        navigate(ROUTE_PATIENT_SYMPTOMS)
        break
      case SourceType.TreatmentCreated:
        navigate(ROUTE_TREATMENTS)
        break
      case SourceType.TreatmentReminder:
        navigate(ROUTE_TREATMENTS)
        break
      case SourceType.NewRegisteredUser:
        navigate(ROUTE_USERS)
        break
      case SourceType.QueryMessageCreated:
        navigate(ROUTE_QUERY)
        break
      case SourceType.MessengerMessageCreated:
        navigate(ROUTE_MESSENGER)
        break
    }
  }

  const getIcon = (type: SourceType): JSX.Element => {
    switch (type) {
      case SourceType.CalendarEventCreated:
      case SourceType.CalendarEventUpdated:
        return <img className={style.icon} src={calendarIcon} alt={calendarIcon} />
      case SourceType.FilesNewCreated:
        return <img className={style.icon} src={resourcesIcon} alt={resourcesIcon} />
      case SourceType.ContentNewArticle:
        return <img className={style.icon} src={libraryIcon} alt={libraryIcon} />
      case SourceType.FormsNewUserForm:
        return <img className={style.icon} src={formIcon} alt={formIcon} />
      case SourceType.FormsUserFormFilled:
        return <img className={style.icon} src={formIcon} alt={formIcon} />
      case SourceType.PatientSymptomCreated:
        return <img className={style.icon} src={symptomIcon} alt={symptomIcon} />
      case SourceType.TreatmentCreated:
        return <img className={style.icon} src={treatmentIcon} alt={treatmentIcon} />
      case SourceType.TreatmentReminder:
        return <img className={style.icon} src={treatmentIcon} alt={treatmentIcon} />
      case SourceType.NewRegisteredUser:
        return <img className={style.icon} src={libraryIcon} alt={libraryIcon} />
      case SourceType.QueryMessageCreated:
        return <img className={style.icon} src={chatIcon} alt={chatIcon} />
      case SourceType.ActivePlanCreated:
        return <img className={style.icon} src={trainingIcon} alt={trainingIcon} />
    }
    return <></>
  }

  return (
    <DashboardSectionCard label={'notifications'}>
      <List className={style.containerList}>
        {filteredNotifications.map((n) => {
          if (!n.id) return null

          const message = notificationMessages.get(n.id)

          if (!message || message.sourceType === SourceType.MessengerMessageCreated) {
            return null
          }
          return (
            <ListItem
              className={style.notificationContainer}
              key={n.id}
              onClick={() => handleOnClick(n)}
            >
              <ListItemIcon>{getIcon(message.sourceType)}</ListItemIcon>
              <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                {message.title}
              </ListItemText>
            </ListItem>
          )
        })}
      </List>
    </DashboardSectionCard>
  )
}
