/* eslint-disable @typescript-eslint/no-unused-vars */
import { DashboardSectionCard } from '../../../../components/section-card/DashboardSectionCard'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ARTICLES } from '../../../../routes/routes-constants'
import { emptyList, ItemList } from '../../../../common/models/ItemList'
import { Article } from '../../../../modules/content/models/Article'
import { Query, QueryParam } from '../../../../common/api/Query'
import { ArticleStatus } from '../../../../modules/content/enums/ArticleStatus'
import { viewImageFile } from '../../../../common/utils/file'
import { getContentContainer } from '../../../../container/content-module'
import { ArticleService } from '../../../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY, LIKES_SERVICE_KEY } from '../../../../modules/content'
import { VisualizationService } from '../../../../modules/content/services/VisualizationService'
import {
  COMMENTS_SERVICE_KEY,
  VISUALIZATIONS_SERVICE_KEY,
} from '../../../../modules/content/container'
import { LikesService } from '../../../../modules/content/services/LikesService'
import { CommentsService } from '../../../../modules/content/services/CommentsService'
import { getFileContainer } from '../../../../container/file-module'
import { FileService } from '../../../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../../../modules/files'
import { getUserCircleContainer } from '../../../../container/user-circle-module'
import { UserCircleService } from '../../../../modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from '../../../../modules/user-circle'
import { Box, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import style from '../chats-card/ChatsCard.module.css'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import queryIcon from '../../../../assets/dashboard/queryIcon.svg'
import { listItemTextStyle } from '../notification-card/NotificationsCardStyleMui'
import esferaLogo from '../../../../assets/brand_logo/smsBlue.svg'
import styleNoti from '../notification-card/NotificationCard.module.scss'
import { RichText } from '../../../../components/text-editor/RichText'

const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const visualizationsService = contentContainer.get<VisualizationService>(VISUALIZATIONS_SERVICE_KEY)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const likesService = contentContainer.get<LikesService>(LIKES_SERVICE_KEY)
const commentsService = contentContainer.get<CommentsService>(COMMENTS_SERVICE_KEY)
const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)

type EducationalContentCardProps = {}
const imageList = new Map()

export const EducationalContentCard: React.FC<EducationalContentCardProps> = () => {
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [circles, setUserCircles] = useState<any>([])
  const navigate = useNavigate()

  const handlerGotoEducationalContent = () => {
    navigate(ROUTE_ARTICLES)
  }

  useEffect(() => {
    const circles: any[] = []
    const logged = JSON.parse(localStorage.getItem('logged user') ?? 'null')
    userCircleService.getUserCirclesByUserId(logged.id).subscribe((res) => {
      if (res && res?.length > 0) {
        res.forEach((obj) => circles.push(Number(obj.circle.id)))
      }
      setUserCircles(circles)
    })
  }, [])

  useEffect(() => {
    if (circles.length === 0) {
      return
    }
    const articleList = emptyList<Article>()
    const articlesQuery = [
      new QueryParam<Article>('status', [ArticleStatus[ArticleStatus.publicado]]),
      new QueryParam<Article>('type', 'article'),
      new QueryParam<Article>('categories', circles),
    ]

    new Promise((resolve) => {
      articlesService
        .getFilteredList(
          new Query({
            query: articlesQuery,
            sort: [{ field: 'createdAt', desc: true }],
          })
        )
        .subscribe((res) => {
          res.items = res.items.sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          })
          articleList.count = 0
          for (let i = 0; i < res.items.length && i < 2; i++) {
            const articleItem = res.items[i]

            articleList.items.push(articleItem)
            articleList.count++

            if (articleItem.slug !== 'slug') {
              filesService.downloadByID(articleItem.slug).subscribe(async (res) => {
                if (res) {
                  const image = await viewImageFile(res)
                  imageList.set(articleItem.slug, image)
                }
                if (i === 0) {
                  resolve(true)
                }
              })
            } else {
              if (i === 0) {
                resolve(true)
              }
            }
          }
          if (res.items.length === 0) {
            resolve(true)
          }
        })
    }).then(() => {
      setArticles(articleList)
    })
  }, [circles])

  const handleSee = (event: any, article: Article) => {
    navigate(`${ROUTE_ARTICLES}/${article.id}`)
  }

  return (
    <DashboardSectionCard label={'library'} showMore={true} onClick={handlerGotoEducationalContent}>
      {articles.items.map((article) => {
        let content = <div></div>
        let articleImage = null

        if (article.slug !== 'slug') {
          articleImage = imageList.get(article.slug) ?? null
        }
        if (article && article.content && article.content.charAt(0) === '[') {
          content = <RichText content={article.content} edit={true} view={true} />
        }
        return (
          <ListItem
            key={article.id}
            onClick={(event) => handleSee(event, article)}
            sx={{
              hover: 'none',
              cursor: 'pointer',
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Box width={'100%'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                mr={1}
                ml={1}
                className={style.chatContainer}
              >
                <ListItemAvatar>
                  <img
                    className={styleNoti.image}
                    src={articleImage === null ? esferaLogo : articleImage}
                  />
                </ListItemAvatar>
                <ListItemText sx={listItemTextStyle} className={styleNoti.listItemText}>
                  <div className={styleNoti.title}>{article.title}</div>
                  <div className={styleNoti.titleEditor}>{content}</div>
                </ListItemText>
              </Box>
            </Box>
          </ListItem>
        )
      })}
    </DashboardSectionCard>
  )
}
