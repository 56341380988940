import { useNavigate } from 'react-router-dom'
import { Box, Chip, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import styles from './View.module.css'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY, TAG_SERVICE_KEY } from '../../modules/content'
import { Article } from '../../modules/content/models/Article'
import { TagService } from '../../modules/content/services/TagService'
import { Tag } from '../../modules/content/models/Tag'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import genericStyle from '../../common/utils/generic.module.css'
import { downloadFile, getFile, viewImageFile } from '../../common/utils/file'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { forkJoin, Observable } from 'rxjs'
import { RichText } from '../../components/text-editor/RichText'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import esferaLogo from '../../assets/brand_logo/smsBlue.svg'
import { articleTypes } from 'modules/content/enums/ArticleCategory'
import { ArticleRelevance, relevanceTypes } from 'modules/content/enums/ArticleRelevance'
import { CircleDTO } from 'modules/circle/models/CircleDTO'
import { getCircleContainer } from 'container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from 'modules/circle'

import profile from '../../assets/esfera/nav-profile-icons/config-profile-icon.svg'
import relevanciaNaranja from '../../assets/esfera/article/relevancia naranja.svg'
import relevanciaRojo from '../../assets/esfera/article/relevancia rojo.svg'
import relevanciaVerde from '../../assets/esfera/article/relevancia verde.svg'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserDTO } from 'modules/users/models/User'
import { getUserContainer } from 'container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from 'modules/users'
import commentIcon from '../../assets/superior_menu/chat.svg'
import { RejectModal } from '../../components/modal/RejectModal'
import { CommentModal } from '../../components/modal/CommentModal'
import { fromModel } from '../../modules/content/models/ArticleDTO'
import { emptyCommentsDTO } from '../../modules/content/models/CommentsDTO'
import { CommentsService } from '../../modules/content/services/CommentsService'
import { COMMENTS_SERVICE_KEY, VISUALIZATIONS_SERVICE_KEY } from '../../modules/content/container'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from '../../common/permission'
import likeIcon from '../../assets/buttons/Like.svg'
import { ArticleStatus } from '../../modules/content/enums/ArticleStatus'
import { Message } from '../../modules/notifications/models/Message'
import { v4 as uuidv4 } from 'uuid'
import { SourceType } from '../../modules/notifications/enums/SourceType'
import { Notification } from '../../modules/notifications/models/Notification'
import { DeliveryStatus } from '../../modules/notifications/enums/DeliveryStatus'
import { PriorityLevel } from '../../modules/notifications/enums/PriorityLevel'
import { TransportType } from '../../common/enums/TransportType'
import { getNotificationContainer } from '../../container/notification-module'
import { MessageService } from '../../modules/notifications/services/MessageService'
import { MESSAGES_SERVICE_KEY, NOTIFICATION_SERVICE_KEY } from '../../modules/notifications'
import { NotificationService } from '../../modules/notifications/services/NotificationService'
import deleteIcon from '../../assets/left_menu/icono-close.svg'
import { VisualizationService } from '../../modules/content/services/VisualizationService'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { emptyVisualizationsDTO } from '../../modules/content/models/VisualizationsDTO'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { File as F } from '../../modules/files/models/File'
import ReactPlayer from 'react-player'
import { ListAddedFiles } from '../files/ListAddedFiles'

const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const tagsService = contentContainer.get<TagService>(TAG_SERVICE_KEY)
const commentsService = contentContainer.get<CommentsService>(COMMENTS_SERVICE_KEY)
const visualizationsService = contentContainer.get<VisualizationService>(VISUALIZATIONS_SERVICE_KEY)

const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

const messengerContainer = getNotificationContainer()
const messengerService = messengerContainer.get<MessageService>(MESSAGES_SERVICE_KEY)

const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const articleCategories = articleTypes()
const articleRelevances = relevanceTypes()

const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authorService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

type ArticlesViewProps = {
  id?: string
}

export function View(props: ArticlesViewProps) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [article, setArticle] = useState<Article>()
  const [image, setImage] = useState<string>()
  const [video, setVideo] = useState<string>()
  // const [files, setFiles] = useState<F[]>([])
  const [tags, setTags] = useState<Tag[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [circle, setCircle] = useState<CircleDTO>()
  const [category, setCategory] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [author, setAuthor] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [fileToDownload, setFileToDownload] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false)
  const [openModalComment, setOpenModalComment] = useState<boolean>(false)
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cancelLoading, setCancelLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    const newVisualization = emptyVisualizationsDTO()
    if (article?.id && article?.userId && article?.circleId) {
      newVisualization.articleID = article?.id
      newVisualization.circleID = article?.circleId
      newVisualization.userID = article?.userId
      visualizationsService.add(newVisualization).subscribe(() => {})
    }
  }, [article?.id])

  useEffect(() => {
    if (!isLoading || !props.id) {
      return
    }
    articlesService.getByID(props.id).subscribe((res) => {
      if (!res) {
        return
      }
      setArticle(res)
    })
  }, [isLoading])

  useEffect(() => {
    const request = article
    if (request != null) {
      if (article?.id)
        filesService.getByArticleID(article.id).subscribe(async (resp) => {
          const file = resp.find((obj) => !obj.mimeType.includes('image'))
          const imageRender = resp.find((obj) => obj.mimeType.includes('image'))
          setFileToDownload(file)
          //  debugger;
          if (imageRender && imageRender.id) {
            filesService.downloadByID(imageRender.id).subscribe(async (res) => {
              if (res) {
                const imageFile = await viewImageFile(res)
                setImage(imageFile)
              }
            })
          }
          if (file && file.id) {
            filesService.downloadByID(file.id).subscribe(async (res) => {
              if (res && res.mimeType.includes('video')) {
                // eslint-disable-next-line @typescript-eslint/await-thenable
                const videoFile = await getFile(res.name, res.mimeType, res.data)
                setVideo(videoFile)
              }
            })
          }
        })
    }
  }, [article])

  const getTags = (ids: string[]): Observable<Tag[]> =>
    forkJoin(ids.map((id) => tagsService.getByID(id)) as unknown) as Observable<Tag[]>

  useEffect(() => {
    if (!article?.id) {
      return
    }

    tagsService.getByArticleID(article.id).subscribe((res) => {
      getTags(res).subscribe((tl) => {
        setTags(tl.filter((t) => t))
      })
      setIsLoading(false)
    })

    circleService.getByID(article.circleId).subscribe((circle) => {
      if (!circle) {
        return
      }
      setCircle(circle)
    })

    articlesService.getAuthorAndCategory(article.id).subscribe((response) => {
      if (response && response.author) {
        setAuthor(response.author)
        setCategory(response.category)
      }
    })
  }, [article])

  const mappedTags = article
    ? tags?.map((t) => {
        return (
          <Chip
            key={`tag-${t.id}`}
            onClick={() => {
              navigate(`${ROUTE_ARTICLES}?${t.id}`)
            }}
            label={<span style={{ fontFamily: 'Poppins' }}>{t.name}</span>}
            size={'small'}
            style={{
              fontSize: '0.8rem',
              fontWeight: 500,
              marginLeft: '10px',
              padding: '2px',
              fontFamily: 'Poppins',
            }}
          />
        )
      })
    : null

  useEffect(() => {
    setLoading(false)
  }, [cancelLoading])

  const goBack = () => navigate(-1)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const downloadFileHandler = () => {
    filesService.downloadByID(fileToDownload.id).subscribe(async (res) => {
      if (res) {
        downloadFile(res.name, res.mimeType, res.data)
      }
    })
  }

  const handleOpenModalComment = () => {
    setOpenModalComment(true)
  }

  const handleCloseCommentModal = () => {
    setOpenModalComment(false)
  }

  const approveArticle = (event: any) => {
    const articleApprove = article
    if (articleApprove != null) {
      articleApprove.status = ArticleStatus[ArticleStatus.publicado]
      articleApprove.userCircleID = articleApprove.ogTitle
      articlesService.update(fromModel(articleApprove)).subscribe((_) => setIsLoading(true))
    }
  }

  const rejectArticle = (description: string) => {
    const articleApprove = article
    if (articleApprove && articleApprove.id) {
      articleApprove.rejectedReason = description
      articleApprove.status = ArticleStatus[ArticleStatus.rechazado]
      articleApprove.userCircleID = articleApprove.ogTitle
      articlesService.update(fromModel(articleApprove)).subscribe((_) => setIsLoading(true))

      const msg = new Message({
        id: uuidv4(),
        title: articleApprove?.title + ' rechazado',
        description,
        message: description,
        sourceType: SourceType.ContentArticleRejected,
        url: articleApprove.id,
      })

      const notif = new Notification({
        userCircleID: articleApprove.ogTitle,
        createdAt: new Date(),
        sendAt: new Date(),
        deliveryStatus: DeliveryStatus.Sent,
        id: uuidv4(),
        message: msg?.id ?? '',
        priorityLevel: PriorityLevel.Normal,
        recipientID: articleApprove.userId,
        senderID: loggedUser?.id ?? '',
        transportType: TransportType.App,
        sourceType: SourceType.ContentArticleRejected,
        endDate: new Date(),
        frequency: 0,
        lastSendAt: new Date(),
      })

      messengerService.add(msg).subscribe(() => {
        notificationService.add(notif).subscribe(() => {})
      })
    }

    setOpenRejectModal(false)
  }

  const handleOpenModalRejectArticle = () => {
    if (article?.id) {
      setOpenRejectModal(true)
    }
  }

  const handleCloseRejectModal = () => {
    setOpenRejectModal(false)
  }

  const uploadComment = (comment: string) => {
    const request = emptyCommentsDTO()
    if (loggedUser && article) {
      request.userID = loggedUser.id
      request.articleID = article.id as string
    }
    request.content = comment
    // setArticle(Object.assign({ ...article}, { comments: [comment] }))
    if (article != null) {
      commentsService.add(request)
      article.comments = [comment]
    }
    setOpenModalComment(false)
  }

  let relevanceIcon = ''
  let relevanceText = ''
  if (article) {
    switch (article.relevance) {
      case ArticleRelevance[ArticleRelevance.baja]:
        relevanceIcon = relevanciaVerde
        relevanceText = articleRelevances[ArticleRelevance.baja]
        break
      case ArticleRelevance[ArticleRelevance.media]:
        relevanceIcon = relevanciaNaranja
        relevanceText = articleRelevances[ArticleRelevance.media]
        break
      case ArticleRelevance[ArticleRelevance.alta]:
        relevanceIcon = relevanciaRojo
        relevanceText = articleRelevances[ArticleRelevance.alta]
        break
      default:
        break
    }
  }

  let contentText
  if (article && article.content && article.content.charAt(0) === '[') {
    contentText = <RichText content={article.content} edit={!!article.id} view={true} />
  } else {
    contentText = <>{article?.content}</>
  }

  return (
    <>
      <Modal open={openRejectModal} onClose={handleCloseRejectModal}>
        <RejectModal
          handleClose={handleCloseRejectModal}
          handleSave={rejectArticle}
          title={article?.title ?? ''}
          author={article?.authorName}
        />
      </Modal>
      <Modal open={openModalComment} onClose={handleCloseCommentModal}>
        <CommentModal
          handleClose={handleCloseCommentModal}
          handleSave={uploadComment}
          title={article?.title ?? ''}
          author={loggedUser?.name}
        />
      </Modal>
      <Box className={genericStyle.pageContainer}>
        {!isLoading ? (
          <Box key="article" className={styles.articleWrapper}>
            <h1 className={styles.title}>{article?.title}</h1>
            {article && article.type === 'article' && (
              <h2 className={styles.tags}>
                <label>{t('tags')}:</label>
                {mappedTags}
              </h2>
            )}

            {article && (
              <>
                <div>
                  <b>{t('circle')}:</b>
                  <label>{category}</label>
                  {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                  {article && article.type === 'article' && (
                    <span>
                      {/* <b>{t('category')}:</b> */}
                      {/* <label>{category}</label> */}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b>{t('relevance')}:</b>
                      {relevanceIcon ? <img src={relevanceIcon}></img> : ''}
                      &nbsp;
                      {relevanceText}
                    </span>
                  )}
                </div>

                <div>
                  <b>{t('date')}:</b>
                  <label>
                    {new Date(article.createdAt).toLocaleDateString()}
                    &nbsp;
                    {new Date(article.createdAt).toLocaleTimeString()}
                  </label>
                </div>
                <img className={styles.thumbnail} src={image ?? ''} />
                {/* <img className={styles.thumbnail} src={image ?? esferaLogo} /> */}
                {contentText}

                {video && <ReactPlayer style={{ margin: 'auto' }} url={video} controls />}
                <div className={styles.creator}>
                  <img src={profile} />
                  &nbsp;&nbsp;
                  {article.authorName}
                </div>
              </>
            )}

            {fileToDownload && (
              <Box className={styles.creator} style={{ borderTop: '0px' }}>
                <h4>Ficheros adjuntos</h4>
                <ListAddedFiles
                  files={[fileToDownload]}
                  handlerClickRemoveFile={() => {}}
                  noRemove={false}
                />
              </Box>
            )}
            <Box
              key="button"
              className={styles.backButton}
              style={{ justifyContent: 'space-between' }}
            >
              {loggedUserService.userCan(Permission.validateContent) &&
                article?.status === 'pendiente' && (
                  <Box style={{ paddingRight: '30%' }}>
                    <AppButton
                      theme={ButtonTheme.Addgroup}
                      label={t('approve')}
                      type={'button'}
                      startIcon={likeIcon}
                      handler={approveArticle}
                    />
                    <AppButton
                      theme={ButtonTheme.RemoveGroup}
                      label={t('reject')}
                      type={'button'}
                      startIcon={deleteIcon}
                      handler={handleOpenModalRejectArticle}
                    />
                  </Box>
                )}
              {loggedUserService.userCan(Permission.CanCommentContent) &&
                article &&
                article.type === 'article' && (
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    label={t('comment')}
                    type={'button'}
                    startIcon={commentIcon}
                    handler={handleOpenModalComment}
                  />
                )}
              <div style={{ margin: '10px' }} />
              {/* { */}
              {/*  fileToDownload && */}
              {/*  <Box className={styles.descarga} style={{ paddingRight: 'auto' }}> */}
              {/*  <AppButton */}
              {/*    theme={ButtonTheme.NewPrimary} */}
              {/*    type={'button'} */}
              {/*    label={t('download')} */}
              {/*    handler={downloadFileHandler} */}
              {/*  /> */}
              {/* </Box> */}
              {/* } */}

              <div style={{ margin: '10px' }} />
              <AppButton
                theme={ButtonTheme.NewSecondary}
                type={'button'}
                label={t('backOneToResources')}
                handler={goBack}
              />
            </Box>
          </Box>
        ) : (
          <LoadingSpinner className={style.loadingSpinner} />
        )}
      </Box>
    </>
  )
}
