import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  POSITIONTYPE_SERVICE_KEY,
  PROFESSIONALTYPE_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from 'modules/users'
import { emptyUserDTO, UserDTO } from 'modules/users/models/User'
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { Alert, Box, MenuItem, Select, Skeleton, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import styles from './Editor.module.css'
import professionalSMS from 'assets/role_icons/ico-rol-profesional.svg'
import patient from 'assets/role_icons/ico-rol-paciente.svg'
import career from 'assets/role_icons/ico-rol-cuidador.svg'
import family from 'assets/role_icons/ico-rol-familiar.svg'
import externProfessional from 'assets/role_icons/ico-rol-externo.svg'
import { ROUTE_USER_PROFILE } from 'routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Header } from 'components/header/Header'
import { Roles } from 'modules/users/enums/Roles'
import { Query } from 'common/api/Query'
import { RolesService } from 'modules/users/services/RolesServices'
import { ProfessionalTypesService } from 'modules/users/services/ProfessionalTypeService'
import { ProfessionalType } from 'modules/users/models/ProfessionalType'
import {
  emptyProfessionalTypeUserDTO,
  ProfessionalTypeUserDTO,
  toModel,
} from 'modules/users/models/ProfessionalTypeUserDTO'
import { v4 as uuidv4, v4 } from 'uuid'
import { getAppContainer, STATUS_SERVICE_KEY } from 'container/app'
import { IStatusService } from 'common/status/StatusService'
import { useGlobalContext } from 'common/utils/GlobalRoleContext'
import { PositionType } from 'modules/users/models/PositionType'
import { toModel as positionToModel } from 'modules/users/models/PositionTypeUserDTO'
import { PositionTypesService } from 'modules/users/services/PositionTypeService'
import {
  emptyProfessionalQueryDTO,
  fromModel,
  ProfessionalQueryDTO,
  toModel as professionalQueryToModel,
} from 'modules/messenger_consulta/models/ProfessionalQueryDTO'
import { getMessengerConsultaContainer } from 'container/messenger-consulta-module'
import { PROFESSIONAL_QUERY_SERVICE_KEY } from 'modules/messenger_consulta'
import { ProfessionalQueryService } from 'modules/messenger_consulta/services/ProfessionalQueryService'
import { ProfessionalQuery } from 'modules/messenger_consulta/models/ProfessionalQuery'
import { emptyPositionTypeDTO, PositionTypeDTO } from 'modules/users/models/PositionTypeDTO'
import icoProfile from 'assets/esfera/profile/profile.svg'
import {
  emptyProfessionalTypeDTO,
  ProfessionalTypeDTO,
} from 'modules/users/models/ProfessionalTypeDTO'
import { emptyFileDTO } from 'modules/files/models/FileDTO'
import { getFileContainer } from 'container/file-module'
import { FILE_SERVICE_KEY, IFileService } from 'modules/files'
import { useGetProfilePhoto } from 'hooks/profilePhoto/useGetProfilePhoto'
import { forkJoin } from 'rxjs'

const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const roleService = userContainer.get<RolesService>(ROLES_SERVICE_KEY)
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)
const positionTypeService = userContainer.get<PositionTypesService>(POSITIONTYPE_SERVICE_KEY)
const professionalQueryService = getMessengerConsultaContainer().get<ProfessionalQueryService>(
  PROFESSIONAL_QUERY_SERVICE_KEY
)
const resourcesService = getFileContainer().get<IFileService>(FILE_SERVICE_KEY)

export function Editor() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()

  const [userToEdit, setUserToEdit] = useState<UserDTO>(emptyUserDTO())
  const [message, setMessage] = useState<string>('')
  const [roles, setRoles] = useState<Map<string, string>>(new Map())
  const [professionalSpecialities, setProfessionalSpecialities] = useState<ProfessionalType[]>([])
  const [professionalPosition, setProfessionalPosition] = useState<PositionType[]>([])
  const [professionalTypeUser, setProfessionalTypeUser] = useState<ProfessionalTypeUserDTO>(
    emptyProfessionalTypeUserDTO()
  )
  const [professionalType, setProfessionalType] = useState<ProfessionalTypeDTO>(
    emptyProfessionalTypeDTO()
  )
  const [positionTypeUser, setPositionTypeUser] = useState<PositionTypeDTO>(emptyPositionTypeDTO())
  const [positionType, setPositionType] = useState<string>('')
  const [professionalQuery, setProfessionalQuery] = useState<ProfessionalQueryDTO>(
    emptyProfessionalQueryDTO()
  )
  const [pQ, setPq] = useState<ProfessionalQuery | undefined>()
  const { role } = useGlobalContext()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { profilePhotoData, refreshProfilePhoto, setRefreshProfilePhoto, isLoading, setIsloading } =
    useGetProfilePhoto()

  useEffect(() => {
    // Set Mapped roles
    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        const map = new Map(res.map((item) => [item.id, item.name]))
        setRoles(map)
      })

    // Set Professional Query On/Off
    if (loggedUser) {
      professionalQueryService.getByUserID(loggedUser?.id).subscribe((res) => {
        setPq(res)
        if (res) {
          setProfessionalQuery(fromModel(res))
        }
      })
    }

    // Professional Type options
    positionTypeService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        setProfessionalPosition(res)
      })

    // Profesional Rol
    professionalTypeService
      .getUserProfessionalTypeByUserID(loggedUser?.id ?? '')
      .subscribe((res) => {
        if (res) {
          setProfessionalTypeUser(res)
        }
      })

    // Professional Type Charge
    positionTypeService.getPositionTypeByUserID(loggedUser?.id ?? '').subscribe((res) => {
      if (res) {
        setPositionTypeUser(res)
      }
    })

    // Professional Specialties
    professionalTypeService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        setProfessionalSpecialities(res)
      })
  }, [])

  useEffect(() => {
    userService.getByID(loggedUser?.id ?? '').subscribe((res) => {
      if (!res) {
        return
      }
      setUserToEdit(res.toDTO())
    })
  }, [roles])

  useEffect(() => {
    professionalTypeService
      .getByID(professionalTypeUser?.professionalTypeID || '')
      .subscribe((res) => {
        if (res) {
          setProfessionalType(res)
        }
      })

    professionalTypeService
      .getUserProfessionalTypeByUserID(loggedUser?.id ?? '')
      .subscribe((res) => {})
  }, [professionalTypeUser])

  useEffect(() => {
    positionTypeService.getByID(positionType).subscribe((res) => {
      if (res) {
        setPositionTypeUser(res)
      }
    })
  }, [positionType])

  useEffect(() => {
    setMessage('')
  }, [userToEdit])

  const fallbackProfile = () => {
    statusService.sendStatus({ variant: 'success' })
    navigate(ROUTE_USER_PROFILE)
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setUserToEdit(Object.assign({ ...userToEdit }, { [e.target.name]: e.target.value }))

  const getImage = () => {
    if (role.length === 0) return
    switch (role[0]?.name) {
      case Roles.Professional:
        return <img className={styles.roleIcon} src={professionalSMS} alt={t(Roles.Professional)} />

      case Roles.Patient:
        return <img className={styles.roleIcon} src={patient} alt={t(Roles.Patient)} />

      case Roles.CarerOrTeacher:
        return <img className={styles.roleIcon} src={career} alt={t(Roles.CarerOrTeacher)} />

      case Roles.FamilyOrTutor:
        return <img className={styles.roleIcon} src={family} alt={t(Roles.FamilyOrTutor)} />

      case Roles.ProfessionalExtern:
        return (
          <img
            className={styles.roleIcon}
            src={externProfessional}
            alt={t(Roles.ProfessionalExtern)}
          />
        )
    }
  }

  const handleSelect = (e: string) => {
    professionalTypeService.getByName(e).subscribe((res) => {
      if (res) {
        const aux: ProfessionalType = res
        setProfessionalType(aux)
      }
    })
  }

  const formatDate = (dateString: Date): string => {
    const date = new Date(dateString)
    let month = '' + (date.getMonth() + 1)
    let day = '' + date.getDate()
    const year = date.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [day, month, year].join('-')
  }

  const calculateAge = (dateString: Date): number => {
    const birthDate = new Date(dateString)
    const today = new Date()
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  const handleSelectPosition = (e: string) => {
    setPositionType(e)
  }

  const cancelHandler = () => navigate(ROUTE_USER_PROFILE)

  const validarNumeroTelefono = (numero: string): boolean => {
    const patronTelefono = /^(?:(?:\+|00)?34)?[\s.]?(\d{9})$/

    const numeroLimpio = numero.replace(/[\s.-]/g, '')

    const esValido = patronTelefono.test(numeroLimpio)

    return esValido
  }

  const handleUploadPhoto = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (file: File) => {
    const selectedFile = emptyFileDTO(loggedUser?.id, '', true)
    if (!loggedUser) return
    selectedFile.id = loggedUser.id
    selectedFile.name = 'profile' + loggedUser.id
    selectedFile.mimeType = file.type
    selectedFile.extension = file.name.split('.').pop() ?? '' // get last text after last dot
    selectedFile.creator = loggedUser.id
    // get data from file
    const reader = new FileReader()

    reader.onload = function (e) {
      const fileData = e.target?.result // Obtiene los datos del archivo en forma de ArrayBuffer

      let base64Data = ''

      if (!fileData) return
      if (fileData instanceof ArrayBuffer) {
        base64Data = btoa(
          new Uint8Array(fileData).reduce((data, byte) => data + String.fromCharCode(byte), '')
        )
      } else {
        // If it's a string (text data), use it directly
        base64Data = btoa(fileData)
      }
      selectedFile.data = base64Data
      // send file to server
      // check if already exist a profile photo
      resourcesService.getByID(loggedUser.id).subscribe((res) => {
        setIsloading(true)
        if (res) {
          resourcesService.update(selectedFile).subscribe((res) => {
            setRefreshProfilePhoto(!refreshProfilePhoto)
          })
        } else {
          resourcesService.add(selectedFile).subscribe((res) => {
            setRefreshProfilePhoto(!refreshProfilePhoto)
          })
        }
      })
    }

    reader.readAsArrayBuffer(file)
  }

  const saveData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (userToEdit.email === '' || userToEdit.email === undefined) {
      setErrorMessage('emailRequired')
      return
    }
    if (!validarNumeroTelefono(userToEdit.phone)) {
      setErrorMessage('phoneInvalid')
      return
    }
    setErrorMessage('')
    professionalQuery.userID = userToEdit.id
    if (professionalQuery.message === '' || professionalQuery.message === undefined) {
      professionalQuery.message = 'Consulta no disponible en este momento.'
    }
    if (pQ) {
      professionalQuery.id = pQ.id
    }
    forkJoin([
      userService.update(userToEdit),
      pQ
        ? professionalQueryService.update(professionalQueryToModel(professionalQuery))
        : professionalQueryService.add(professionalQueryToModel(professionalQuery)),
      professionalTypeService.getUserProfessionalTypeByUserID(userToEdit.id),
      positionTypeService.createPositionTypeUser(
        positionToModel({ id: v4(), positionTypeID: positionType, userID: userToEdit.id })
      ),
    ]).subscribe((array) => {
      const res = array[2]
      if (res) {
        res.professionalTypeID = professionalType.id
        professionalTypeService.updateProfessionalTypeUser(toModel(res)).subscribe((res) => {
          fallbackProfile()
        })
      } else {
        professionalTypeUser.id = uuidv4()
        professionalTypeUser.professionalTypeID = professionalType.id
        professionalTypeUser.userID = userToEdit.id
        professionalTypeService
          .createProfessionalTypeUser(toModel(professionalTypeUser))
          .subscribe((res) => {
            fallbackProfile()
          })
      }
    })
  }

  const hasRelevantRole = [
    'professionalSMS',
    'managerHealthChild',
    'managerActivePlan',
    'managerEpileptic',
  ].some((role) => loggedUser?.roles?.includes(role))

  const onlyCaseManager = ['managerHealthChild', 'managerActivePlan', 'managerEpileptic'].some(
    (role) => loggedUser?.roles?.includes(role)
  )

  const onlyCaseManagerText = () => {
    if (loggedUser?.roles?.includes('managerActivePlan')) return t('activePlan')
    if (loggedUser?.roles?.includes('managerHealthChild')) return t('healthykid')
    if (loggedUser?.roles?.includes('managerEpileptic')) return t('pediatricEpilepsy')
    return ''
  }

  return (
    <Box className={styles.editorContainer}>
      <Box className={styles.editorForm}>
        <Header
          label={
            loggedUser?.roles?.includes('patient') ? t('patientProfile') : t('professionalProfile')
          }
        />
        <Box className={styles.editorRow}>
          <Box>
            {isLoading && (
              <Skeleton
                style={{ marginRight: '40px', marginLeft: '8px' }}
                variant="circular"
                width={100}
                height={100}
              />
            )}
            {!isLoading && (
              <Box className={styles.editorImageColumn}>
                <Box className={styles.editorImageBG}>
                  {profilePhotoData !== '' ? (
                    <>
                      <img
                        style={{
                          height: '110px',
                          width: '110px',
                          background: '#FFF',
                          borderRadius: 100,
                        }}
                        src={`data:image/jpeg;base64,${profilePhotoData}`}
                        alt={'selectedProfilePhoto'}
                      />
                    </>
                  ) : (
                    <img src={icoProfile} alt={icoProfile} />
                  )}
                </Box>
                {
                  // Change for patient when security issue is fixed inside includes
                  loggedUser?.roles?.includes('changeForPatientWhenSecurityIssueFixed') && (
                    <Box sx={{ marginTop: '12px' }}>
                      <AppButton
                        theme={ButtonTheme.NewPrimary}
                        type={'button'}
                        label={t('uploadPhoto')}
                        span={true}
                        handler={handleUploadPhoto}
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          const file = e.target.files![0]
                          if (file) {
                            handleFileChange(file)
                          }
                        }}
                      />
                    </Box>
                  )
                }
              </Box>
            )}
          </Box>

          <Box className={styles.editorFields}>
            {loggedUser?.roles?.includes('patient') && (
              <Box className={styles.editorFieldBox}>
                <p className={styles.editorFieldLabel}>{t('fullName')}</p>
                <p className={styles.editorTextResponsive}>{userToEdit.name}</p>
              </Box>
            )}

            {loggedUser?.roles?.includes('professionalSMS') && (
              <Box className={styles.editorFieldBox}>
                <p className={styles.editorFieldLabel}>{t('fullName')}</p>
                <TextField
                  id={'name'}
                  key={'name'}
                  name="name"
                  className={styles.textField}
                  value={userToEdit.name}
                  type="name"
                  required={true}
                  variant={'outlined'}
                  size={'small'}
                  disabled={true}
                />
              </Box>
            )}

            {loggedUser?.roles?.includes('patient') && (
              <>
                <Box className={styles.editorFieldBox}>
                  <p className={styles.editorFieldLabel}>{t('birthdate')}</p>
                  <p className={styles.editorTextResponsive}>{formatDate(userToEdit.birthDate)}</p>
                </Box>
                <Box className={styles.editorFieldBox}>
                  <p className={styles.editorFieldLabel}>{t('age')}</p>
                  <p className={styles.editorTextResponsive}>
                    {calculateAge(userToEdit.birthDate)}
                  </p>
                </Box>

                <Box className={styles.editorFieldBox}>
                  <p className={styles.editorFieldLabel}>{t('dni')}</p>
                  <p className={styles.editorTextResponsive}>{userToEdit.dni}</p>
                </Box>

                <Box className={styles.editorFieldBox}>
                  <p className={styles.editorFieldLabel}>{t('cip')}</p>
                  <p className={styles.editorTextResponsive}> {userToEdit.cip}</p>
                </Box>
              </>
            )}

            <Box className={styles.editorFieldBox}>
              <p className={styles.editorFieldLabel}>{t('email')}</p>
              <TextField
                id={'email'}
                key={'email'}
                name="email"
                className={styles.textField}
                value={userToEdit.email}
                type="email"
                onChange={handleInput}
                variant={'outlined'}
                size={'small'}
              />
            </Box>

            <Box className={styles.editorFieldBox}>
              <p className={styles.editorFieldLabel}>{t('phone')}</p>
              <TextField
                id={'phone'}
                key={'phone'}
                name="phone"
                type="tel"
                className={styles.textField}
                value={userToEdit.phone}
                onChange={handleInput}
                variant={'outlined'}
                size={'small'}
              />
            </Box>

            {loggedUser?.roles?.includes('patient') && (
              <Box className={styles.editorFieldBox}>
                <p className={styles.editorFieldLabel}>{t('role') + '*'}</p>
                <p className={styles.editorTextResponsive}>
                  {t(role && role.length > 1 ? role[1].name : role[0]?.name || '')}
                </p>
              </Box>
            )}

            {hasRelevantRole && (
              <Box className={styles.editorFieldBox}>
                <p className={styles.editorFieldLabel}>{t('role') + '*'}</p>
                <TextField
                  id="role"
                  key="role"
                  name="role"
                  className={styles.textField}
                  InputProps={{
                    startAdornment: getImage(),
                  }}
                  value={
                    role.length && role.find((r) => r.name === 'professionalSMS')
                      ? t(role && role.length > 1 ? role[1].name : role[0]?.name || '')
                      : t('caseManager')
                  }
                  type="text"
                  required={true}
                  variant={'outlined'}
                  size={'small'}
                  disabled={true}
                />
              </Box>
            )}

            {onlyCaseManager && (
              <Box className={styles.editorFieldBox}>
                <p className={styles.editorFieldLabel}>{t('circle') + '*'}</p>
                <TextField
                  id="role"
                  key="role"
                  name="role"
                  className={styles.textField}
                  InputProps={{
                    startAdornment: getImage(),
                  }}
                  value={onlyCaseManagerText()}
                  type="text"
                  required={true}
                  variant={'outlined'}
                  size={'small'}
                  disabled={true}
                />
              </Box>
            )}

            <Box className={styles.editorFieldBox}>
              {role.length && role.find((r) => r.name === 'professionalSMS') && (
                <>
                  <p className={styles.editorFieldLabel}>{t('professionalType') + '*'}</p>
                  <Select
                    id="specialty-select"
                    key="specialty-select"
                    className={styles.textField}
                    style={{ height: '40px' }}
                    value={(professionalType && professionalType.name) || ''}
                    name="professionalType"
                    variant={'outlined'}
                    onChange={(e) => handleSelect(e.target.value)}
                    required>
                    {Object.values(professionalSpecialities).map((k) => (
                      <MenuItem key={k.id} value={k.name}>
                        {k.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </Box>
            {loggedUser?.roles?.includes('professionalSMS') && (
              <Box className={styles.editorFieldBox}>
                <p className={styles.editorFieldLabel}>{t('positionType') + '*'}</p>
                <Select
                  id="position-select"
                  key="position-select"
                  className={styles.textField}
                  style={{ height: '40px' }}
                  value={positionTypeUser.id}
                  name="positionType"
                  variant={'outlined'}
                  onChange={(e) => {
                    handleSelectPosition(e.target.value)
                  }}
                  required>
                  {Object.values(professionalPosition).map((k) => (
                    <MenuItem key={k.id} value={k.id}>
                      {k.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            {loggedUser?.roles?.includes('professionalSMS') &&
              !loggedUser?.roles?.find((rol) => rol.includes('manager')) && (
                <Box className={styles.editorCheckbox}>
                  <p className={styles.editorFieldLabel}>{t('acceptChats')}</p>

                  <Box className={styles.radioContainer}>
                    <label className={styles.radioLabel}>
                      <input
                        type="radio"
                        name="acceptChats"
                        value="yes"
                        checked={userToEdit.acceptChat}
                        onChange={() =>
                          setUserToEdit(Object.assign({ ...userToEdit }, { acceptChat: true }))
                        }
                        className={styles.check}
                        disabled={false}
                      />
                      Sí
                    </label>

                    <label className={styles.radioLabel}>
                      <input
                        type="radio"
                        name="acceptChats"
                        value="no"
                        checked={!userToEdit.acceptChat}
                        onChange={() =>
                          setUserToEdit(Object.assign({ ...userToEdit }, { acceptChat: false }))
                        }
                        className={styles.check}
                        disabled={false}
                      />
                      No
                    </label>
                  </Box>
                </Box>
              )}
            {loggedUser?.roles?.includes('professionalSMS') && (
              <Box className={styles.editorCheckbox}>
                <p className={styles.editorFieldLabel}>{t('acceptQuerys') + ':'}</p>
                <Box className={styles.radioContainer}>
                  <label className={styles.radioLabel}>
                    <input
                      type="radio"
                      name={'acceptQuerys'}
                      value="yes"
                      checked={professionalQuery.acceptConsulta === 1}
                      onChange={() => {
                        setProfessionalQuery(
                          Object.assign({ ...professionalQuery }, { acceptConsulta: 1 })
                        )
                      }}
                      className={styles.check}
                      disabled={false}
                    />
                    Sí
                  </label>

                  <label className={styles.radioLabel}>
                    <input
                      type="radio"
                      name={'acceptQuerys'}
                      value="no"
                      checked={professionalQuery.acceptConsulta === 0}
                      onChange={() => {
                        setProfessionalQuery(
                          Object.assign({ ...professionalQuery }, { acceptConsulta: 0 })
                        )
                      }}
                      className={styles.check}
                      disabled={false}
                    />
                    No
                  </label>
                </Box>
              </Box>
            )}
            {loggedUser?.roles?.includes('professionalSMS') && (
              <Box className={styles.editorCheckbox}>
                <p className={styles.editorFieldLabel}>{t('acceptVideoCalls')}</p>

                <Box className={styles.radioContainer}>
                  <label className={styles.radioLabel}>
                    <input
                      type="radio"
                      name="acceptVideoCalls"
                      value="yes"
                      checked={userToEdit.acceptVideoCalls}
                      onChange={() =>
                        setUserToEdit(Object.assign({ ...userToEdit }, { acceptVideoCalls: true }))
                      }
                      className={styles.check}
                      disabled={false}
                    />
                    Sí
                  </label>

                  <label className={styles.radioLabel}>
                    <input
                      type="radio"
                      name="acceptVideoCalls"
                      value="no"
                      checked={!userToEdit.acceptVideoCalls}
                      onChange={() =>
                        setUserToEdit(Object.assign({ ...userToEdit }, { acceptVideoCalls: false }))
                      }
                      className={styles.check}
                      disabled={false}
                    />
                    No
                  </label>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {message && (
        <Box>
          <Alert severity="success" key="message" id="message">
            {t(message)}
          </Alert>
        </Box>
      )}
      {errorMessage && (
        <Box>
          <Alert severity="warning" key="errorMessage" id="errorMessage">
            {t(errorMessage)}
          </Alert>
        </Box>
      )}
      <Box className={styles.buttonContainer}>
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('cancel')}
          handler={() => cancelHandler()}
        />
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'submit'}
          label={t('save')}
          handler={saveData}
        />
      </Box>
    </Box>
  )
}
