import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import { Query } from '../../common/api/Query'
import { getContentContainer } from '../../container/content-module'
import { TAG_SERVICE_KEY } from '../../modules/content'
import { LoadingSpinner } from 'components/loading-spinner/LoadingSpinner'
import { TagService } from '../../modules/content/services/TagService'
import genericStyle from '../../common/utils/generic.module.css'
import styles from './Search.module.css'
import { TableGrid } from './TableGrid'
import { Header } from '../../components/header/Header'
import tick from '../../assets/calendar/tick.svg'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'

const contentContainer = getContentContainer()
const tagsService = contentContainer.get<TagService>(TAG_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)

type SearchProps = {
  tagList: string[]
}

export type LibraryFilter = {
  diabetes: boolean
  tension: boolean
  porfiria: boolean
  niñoSano: boolean
}

export function Search(props: SearchProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [tagList, setTagList] = useState<Map<string, string>>(new Map())
  const [filter, setFilter] = useState<any>({})
  const [circles, setUserCircles] = useState<any>([])
  const [filterToSend, setFilterToSend] = useState<string[]>([])
  // const navigate = useNavigate()

  const { diabetes } = filter

  useEffect(() => {
    tagsService.getFilteredList(new Query({ sort: [{ field: 'name' }] })).subscribe((res) => {
      const map = new Map()
      const mapString: string[] = []
      res.items.forEach((o: any) => map.set(o.name, o.id))
      res.items.forEach((obj) => mapString.push(obj.id))
      setTagList(map)
      setIsLoading(false)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const filter = JSON.parse(sessionStorage.getItem('initFilter'))
      if (filter) {
        setFilterToSend(filter)
        const aux: any[] = []
        Array.from(filter).forEach((obj) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          aux.push({ [obj]: true })
        })
        setFilter(aux)
      }
    })
    const circles: any[] = []
    const logged = JSON.parse(localStorage.getItem('logged user') ?? 'null')
    userCircleService.getUserCirclesByUserId(logged.id).subscribe((res) => {
      if (res && res?.length > 0) {
        res.forEach((obj) => circles.push(Number(obj.circle.id)))
      }
      setUserCircles(circles)
    })
  }, [])

  useEffect(() => {
    tagList.forEach((value, key, map) => {
      return (
        <label className={styles.labelCheck}>
          <input
            type="checkbox"
            name={key}
            className={styles.checkboxInput}
            onChange={handleCheck}
            checked={diabetes}
          />
          {value}
          {diabetes && <img src={tick} alt="Icono de tick" className={styles.checkboxImage} />}
        </label>
      )
    })
  }, [])

  useEffect(() => {
    const aux = {}
    Array.from(tagList).forEach((obj) => {
      if (sessionStorage.getItem('initFilter')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        JSON.parse(sessionStorage.getItem('initFilter')).forEach((initFilter) => {
          if (initFilter === obj[1]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            aux[obj[1]] = true
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            aux[obj[1]] = false
          }
        })
      }
    })
    setFilter(aux)
  }, [tagList])

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let aux: string[] = []
    Object.assign(aux, filterToSend)
    setFilter({
      ...filter,
      [event.target.name]: event.target.checked,
    })
    if (filterToSend.find((obj) => obj === event.target.name)) {
      aux = aux.filter((obj) => obj !== event.target.name)
      sessionStorage.setItem('initFilter', JSON.stringify(aux))
      setFilterToSend(aux)
    } else {
      aux.push(event.target.name)
      sessionStorage.setItem('initFilter', JSON.stringify(aux))
      setFilterToSend(aux)
    }
  }

  return (
    <>
      {!isLoading ? (
        <Box className={genericStyle.pageContainer}>
          <Box>
            <Header label={t('filters')} />
          </Box>

          <div className={styles.legendCheck2}>
            <span className={styles.filterCategory} style={{ textWrap: 'nowrap' }}>
              Filtrar por etiqueta:
            </span>
            <div className={styles.legendCheck}>
              <div className={styles.legendCheck2} style={{ flexWrap: 'wrap' }}>
                {Array.from(tagList).map((obj, value) => {
                  return (
                    <label key={value} className={styles.labelCheck} style={{ textWrap: 'nowrap' }}>
                      <input
                        type="checkbox"
                        name={obj[1]}
                        className={styles.checkboxInput}
                        onChange={handleCheck}
                        checked={filter[obj[1]]}
                      />
                      {obj[0]}
                      {filter[obj[1]] && (
                        <img src={tick} alt="Icono de tick" className={styles.checkboxImage} />
                      )}
                    </label>
                  )
                })}
              </div>
            </div>
          </div>

          <Box mb={3}>
            <TableGrid tagIDList={filterToSend} circles={circles} />
          </Box>
        </Box>
      ) : (
        <LoadingSpinner className={loaderStyles.loadingSpinner} />
      )}
    </>
  )
}
